.details {
    margin: 3rem;
    margin-top: 80px;
}


.detailsThumbnail {
    border: 1px solid black;
  }

.comment {
    margin-top: 1rem;
    overflow-wrap: break-word;
    word-break: break-word;
}

.anchor {
    margin-top: 1.75rem;
}

.page-btn {
  font-weight: bold !important;
  color: #1B5956 !important;
  font-size: 100% !important;
}
