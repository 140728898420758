.mobile {
  position: relative;
}

.desktop {
  position: absolute;
  top: 2.5rem;
  left: 0;
}

iframe {
  border: none;
  width: 100%;
  height: 100%;
}
