@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");

.app {
  font-family: "Source Sans Pro";
  font-size: 20px;
}

.timenrn {
  font-family: "timenrn";
}
