// Override default variables before the import
$theme-colors: (
  "primary": #1b5956,
  "plan": #0e8153,
  "draft": #0e8153,
  "coi": #200066,
  "written": #cd0099,
  "other": #cd0099,
  "file": #cd0099,
  "anchor": #c8c8c8,
);

.coi {
  color: map-get($theme-colors, "coi");
}

.plan {
  color: map-get($theme-colors, "plan");
}

.draft {
  color: map-get($theme-colors, "plan");
}

.written {
  color: map-get($theme-colors, "written");
}

.nav-tabs .nav-link {
  border-color: #ccc ;
  margin-left: 3px;
  color: #444 ;
}

a.nav-item.nav-link.active {
  background-color: map-get($theme-colors, "primary");
  color: white ;
}

.custom > a {
  color: #444 ;
}

.link {
  font-weight: bold;
  text-decoration: underline;
}

p > a {
  color: map-get($theme-colors, "primary") ;
}

i > a {
  color: map-get($theme-colors, "primary") ;
  font-family: bold ;
}

a:visited {
  color: transparent;
}

span {
  font-weight: bold;
}

.button-group {
  align-items: stretch;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  display: flexbox;
  .btn {
    min-width: 230px;
    min-height: 60px;
    color: white !important;
  }
  .custom {
    color: black !important;
    min-height: 30px;
    border-radius: 0 !important;
    border-width: 0 !important;
    background-color: #a8a8a8 !important;

    &:hover {
      background-color: #8f8f8f !important;
    }
  }
}

.btn-group > .btn {
  margin: 0;
}

.jump {
  margin-top: -2rem;
  padding-top: 2rem;
  margin-bottom: 1rem;
}

table {
  width: 100%;
  margin: 0 0 1rem;
  border-collapse: separate;
}

thead {
  text-align: center;
  background: #000;
  color: #fff;
  font-weight: bold;
  font-size: 90%;
}

th {
  padding: 0.75rem;
  display: table-cell;
}

tbody {
  text-align: center;
  font-size: 85%;
  width: auto;
}

td:first-child {
  text-align: left;
}

td:last-child {
  text-align: right;
}

td {
  padding: 0.5rem;
  display: table-cell;
}

.tag-expo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  font-size: 75%;
  color: map-get($theme-colors, "primary") !important;
}

.card-link + .card-link {
  margin-left: 0 !important;
}
.post-tag {
  display: inline-block;
  background: map-get($theme-colors, "primary");
  padding: 0 0.5rem;
  margin: 0;
  margin-top: 8px;
  border-top-left-radius: 1.5rem 1rem;
  border-bottom-left-radius: 1.5rem 1rem;

  border-top-right-radius: 1.5rem 1rem;
  border-bottom-right-radius: 1.5rem 1rem;
  color: white;
  font-size: 90%;

  &:hover {
    color: white;
  }
}

.form-control {
  user-select: text;
}

// $body-font: "Georgia", serif;

$input-placeholder-color: rgb(204, 204, 204);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

.btn {
  height: auto;
  width: auto;
  border-radius: 0.5rem;
  margin: 0.25rem;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

a.btn:visited {
  color: #fff !important;
}

.card-body {
  overflow: hidden;
}
a.post-tag:visited {
  color: #fff !important;
}

@media only screen and (max-device-width: 500px) {
  a.navbar-brand {
    max-width: 70%;
    white-space: break-spaces;
  }

  .app.m-5 {
    margin: 0 !important;
    margin-top: 150px !important;
  }

  .m-5.p-5 {
    margin: 0 !important;
    padding: 0 !important;
  }

  form.p-5 {
    padding: 0 !important;
  }

  form .m-5 {
    margin: 10px !important;
  }
}

@media only screen and (max-width: 950px) {
  .hide-small {
    display: none;
  }
}
