.anchor {
    @extend .badge;
    background-color: #c8c8c8;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.anchor > h1 {
    font-size: unset;
    font-weight: unset;
    margin: 0;
    padding: 0;
}

.anchor > h2 {
    font-size: unset;
    font-weight: unset;
    margin: 0;
    padding: 0;
}

@import '../../assets/custom'
